<template>
  <div>
    <h1>Dashboard</h1>
  </div>
</template>

<script>
export default {
  created() {
    //const loading = this.$vs.loading({type: 'points'})
  }
}
</script>
